import React from 'react';

import ScrollDownItem from '@/core/components/ScrollDownItem';
import type { UpdateAction } from '@/core/lib/new-architecture/domain/donation.domain';
import Routes from '@/core/lib/router/routes';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface UpdateActionComponentProps {
  action: UpdateAction;
}

const UpdateActionComponent: React.FC<UpdateActionComponentProps> = ({ action }) => {
  const { t } = useTranslationContext(['common']);

  const href = new Routes.ModifyDonationRoute(action.donationId);

  return <ScrollDownItem type="link" href={href} content={t(action.text, { ns: 'common' })} icon="edit" disabled={action.isDisabled} />;
};

export default UpdateActionComponent;
