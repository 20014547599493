import React from 'react';

import DonationCardCity from '@/core/components/DonationCard/DonationCardCity';
import DonationCardDate from '@/core/components/DonationCard/DonationCardDate';
import DonationCardDistance from '@/core/components/DonationCard/DonationCardDistance';
import DonationCardPhoto from '@/core/components/DonationCard/DonationCardPhoto';
import DonationCardTitle from '@/core/components/DonationCard/DonationCardTitle';
import DonationsSecondaryActions from '@/core/components/DonationDetails/DonationActions/DonationsSecondaryActions';
import Icons from '@/core/components/Icons';
import useDisclosure from '@/core/hooks/useDisclosure';
import { useComponentsContext } from '@/core/lib/components/components.context';
import type DonationDomain from '@/core/lib/new-architecture/domain/donation.domain';
import { useRouterContext } from '@/core/lib/router/router.context';
import Routes from '@/core/lib/router/routes';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface HDonationCardProps {
  donation: DonationDomain;
}

const HDonationCard: React.FC<HDonationCardProps> = ({ donation }) => {
  const { t } = useTranslationContext(['common']);
  const { Link } = useRouterContext();
  const { DonationCardState } = useComponentsContext();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { personae } = donation;
  const { secondary } = donation.getActions();
  const id = donation.getId();
  const title = donation.getTitle();
  const city = donation.getCityLabel();
  const distance = donation.getDistance();
  const photo = donation.getFirstPhoto();
  const state = donation.getState();
  const lastPublishedAt = donation.getLastPublishedAt();
  const onOpenActions: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    e.stopPropagation();
    onOpen();
  };

  return (
    <Link href={new Routes.DonationRoute(id)} className="flex w-full flex-row gap-2 rounded-2 border border-stroke-secondary p-2 lg:p-3">
      <DonationCardPhoto photo={photo} title={title} horizontal />

      <div className="flex w-full flex-col gap-2">
        <div>
          <div className="relative flex w-full items-center">
            <DonationCardTitle title={title} />
            <button
              type="button"
              onClick={onOpenActions}
              aria-label={t('buttons.more.aria-label', { ns: 'common' })}
              className="absolute right-0 flex size-8 shrink-0 items-center justify-center rounded-full hover:bg-bg-button-ghost-hover"
            >
              <Icons icon="more-vertical" size="24" />
            </button>
            <DonationsSecondaryActions actions={secondary} isOpen={isOpen} onClose={onClose} />
          </div>
          <DonationCardState state={state} personae={personae} />
        </div>
        <DonationCardCity city={city} />
        <div className="flex flex-row gap-3 lg:gap-4">
          <DonationCardDistance distance={distance} />
          <DonationCardDate date={lastPublishedAt} />
        </div>
      </div>
    </Link>
  );
};

export default HDonationCard;
