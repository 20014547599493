import React from 'react';

import { UnreserveActionModal } from '@/core/components/ConversationActions/actions/UnreserveActionComponent';
import ScrollDownItem from '@/core/components/ScrollDownItem';
import { ToastStatus } from '@/core/components/Toast/Toast';
import useDisclosure from '@/core/hooks/useDisclosure';
import Actions from '@/core/lib/new-architecture/actions';
import { UnreserveAction } from '@/core/lib/new-architecture/domain/donation.domain';
import { useToastContext } from '@/core/lib/toast/toast.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface UnreserveActionComponentProps {
  action: UnreserveAction;
}

const UnreserveActionComponent: React.FC<UnreserveActionComponentProps> = ({ action }) => {
  const { t } = useTranslationContext(['common']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onUnreserve, isLoading } = Actions.conversation.useUnreserve();
  const toast = useToastContext();

  const onSubmit = async () => {
    await onUnreserve(action.donationId);
    toast?.open(t('unreserve.success', { ns: 'common' }), 2000, ToastStatus.SUCCESS);
    onClose();
  };

  return (
    <>
      <ScrollDownItem type="button" icon="unlock" content={t('components.messages.message-list.giver-action-giver-cancel', { ns: 'common' })} onClick={onOpen} />
      <UnreserveActionModal isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} isLoading={isLoading} />
    </>
  );
};

export default UnreserveActionComponent;
