import React from 'react';

import Button from '@/core/components/Button';
import Icons from '@/core/components/Icons';
import Modal from '@/core/components/Modal';
import useDisclosure from '@/core/hooks/useDisclosure';
import Actions from '@/core/lib/new-architecture/actions';
import type { UnreserveAction } from '@/core/lib/new-architecture/domain/conversation.domain';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface UnreserveActionComponentProps {
  action: UnreserveAction;
}

const UnreserveActionComponent: React.FC<UnreserveActionComponentProps> = ({ action }) => {
  const { t } = useTranslationContext(['common']);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { onUnreserve, isLoading } = Actions.conversation.useUnreserve();

  const onSubmit = () => onUnreserve(action.donationId);

  return (
    <>
      <Button type="submit" variant="secondary" size="medium" isLoading={isLoading} onClick={onOpen}>
        {t('components.messages.message-list.giver-action-giver-cancel', { ns: 'common' })}
      </Button>
      <UnreserveActionModal isOpen={isOpen} onClose={onClose} onSubmit={onSubmit} isLoading={isLoading} />
    </>
  );
};

export const UnreserveActionModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  isLoading: boolean;
  onSubmit: () => void;
}> = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const { t } = useTranslationContext(['common']);

  return (
    <Modal isOpen={isOpen} onClose={onClose} type="popup">
      <div aria-labelledby="header-content" className="relative z-10 flex w-full items-center justify-center bg-bg-primary lg:relative">
        <div id="header-content" className="text-body-primary grow text-center font-semibold">
          {t('components.messages.message-list.giver-action-unreserve-modal-title', { ns: 'common' })}
        </div>
        <button
          type="button"
          onClick={onClose}
          aria-label={t('close', { ns: 'common' })}
          className="absolute right-0 flex size-8 shrink-0 items-center justify-center rounded-full hover:bg-bg-button-ghost-hover"
        >
          <Icons icon="close" size="24" />
        </button>
      </div>

      <div className="flex flex-col gap-6 pt-3 text-center">
        <p className="text-body-secondary font-medium text-content-primary">{t('components.messages.message-list.giver-action-unreserve-modal-message', { ns: 'common' })}</p>
        <div className="grid grid-cols-2 gap-4">
          <Button type="submit" size="medium" variant="secondary" isLoading={isLoading} onClick={onClose}>
            <p>{t('components.messages.message-list.giver-action-unreserve-modal-cancel', { ns: 'common' })}</p>
          </Button>
          <Button type="submit" size="medium" onClick={onSubmit}>
            <p>{t('components.messages.message-list.giver-action-unreserve-modal-confirm', { ns: 'common' })}</p>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default UnreserveActionComponent;
