import React from 'react';

import ScrollDownItem from '@/core/components/ScrollDownItem';
import { ToastStatus } from '@/core/components/Toast/Toast';
import { useEnvContext } from '@/core/lib/env/env.context';
import { useNativeContext } from '@/core/lib/native/native.context';
import type { ShareAction } from '@/core/lib/new-architecture/domain/donation.domain';
import Routes from '@/core/lib/router/routes';
import { useShareContext } from '@/core/lib/share/share.context';
import { useToastContext } from '@/core/lib/toast/toast.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface ShareActionComponentProps {
  action: ShareAction;
  onClose?: () => void;
}

const ShareActionComponent: React.FC<ShareActionComponentProps> = ({ action, onClose }) => {
  const { BASE_URL } = useEnvContext();
  const { isNative } = useNativeContext();
  const { t } = useTranslationContext(['common']);

  const toast = useToastContext();
  const { canShare, shareUrl } = useShareContext();

  const onShareDonation = async () => {
    if (isNative && (await canShare())) {
      const url = `${BASE_URL}${new Routes.DonationRoute(action.donationId as number).resolve()}`;
      const params = {
        title: action.donationTitle,
        text: t('components.donation.details.actions.share-link-text', { ns: 'common' }),
        url,
        dialogTitle: t('components.donation.details.actions.share-link', { ns: 'common' }),
      };
      shareUrl(params);
      return;
    }
    await navigator.clipboard.writeText(`${window.location.origin}${new Routes.DonationRoute(action.donationId as number).resolve()}`);
    toast?.open(t('components.donation.details.actions.copy-link', { ns: 'common' }), 2000, ToastStatus.SUCCESS);
    if (onClose) onClose();
  };

  return <ScrollDownItem type="button" icon="share" content={t(action.text, { ns: 'common' })} onClick={onShareDonation} disabled={action.isDisabled} />;
};

export default ShareActionComponent;
