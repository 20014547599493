import React from 'react';

import DonationReportModal from '@/core/components/DonationReportModal';
import ScrollDownItem from '@/core/components/ScrollDownItem';
import useDisclosure from '@/core/hooks/useDisclosure';
import type { ReportAction } from '@/core/lib/new-architecture/domain/donation.domain';
import { useTranslationContext } from '@/core/lib/translation/translation.context';

interface ReportActionComponentProps {
  action: ReportAction;
}

const ReportActionComponent: React.FC<ReportActionComponentProps> = ({ action }) => {
  const { t } = useTranslationContext(['common']);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <ScrollDownItem type="button" icon="flag" content={t(action.text, { ns: 'common' })} onClick={onOpen} disabled={action.isDisabled} />
      <DonationReportModal donationId={action.donationId} isOpen={isOpen} onClose={onClose} />
    </>
  );
};

export default ReportActionComponent;
