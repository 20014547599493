import React from 'react';

import BottomSheet from '@/core/components/BottomSheet';
import ReportActionComponent from '@/core/components/DonationDetails/DonationActions/actions/ReportActionComponent';
import ShareActionComponent from '@/core/components/DonationDetails/DonationActions/actions/ShareActionComponent';
import UnreserveActionComponent from '@/core/components/DonationDetails/DonationActions/actions/UnreserveActionComponent';
import UpdateActionComponent from '@/core/components/DonationDetails/DonationActions/actions/UpdateActionComponent';
import type { DonationAction } from '@/core/lib/new-architecture/domain/donation.domain';
import { DonationActionType } from '@/core/lib/new-architecture/domain/donation.domain';

interface DonationsSecondaryActionsProps {
  isOpen: boolean;
  onClose: () => void;
  actions: DonationAction[];
}

const DonationsSecondaryActions: React.FC<DonationsSecondaryActionsProps> = ({ isOpen, onClose, actions }) => {
  const renderActions = actions.reduce((acc, action) => {
    switch (action.action) {
      case DonationActionType.SHARE:
        return [...acc, <ShareActionComponent key={action.action} action={action} onClose={onClose} />];

      case DonationActionType.REPORT:
        return [...acc, <ReportActionComponent key={action.action} action={action} />];

      case DonationActionType.UPDATE:
        return [...acc, <UpdateActionComponent key={action.action} action={action} />];

      case DonationActionType.UNRESERVE:
        return [...acc, <UnreserveActionComponent key={action.action} action={action} />];

      default:
        return acc;
    }
  }, [] as React.ReactElement[]);

  if (renderActions.length === 0) {
    return null;
  }

  return (
    <BottomSheet isOpen={isOpen} onClose={onClose}>
      {/* eslint-disable-next-line */}
      <div className="flex flex-col p-4" onClick={e => e.stopPropagation()}>
        {renderActions}
      </div>
    </BottomSheet>
  );
};

export default DonationsSecondaryActions;
