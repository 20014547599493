import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

import Alert from '@/core/components/Alert';
import Button from '@/core/components/Button';
import FormContainer from '@/core/components/FormContainer';
import Header from '@/core/components/Header';
import HintMessage from '@/core/components/HintMessage';
import CheckboxInput from '@/core/components/inputs/CheckboxInput';
import TextAreaInput from '@/core/components/inputs/TextAreaInput';
import Modal from '@/core/components/Modal';
import { ToastStatus } from '@/core/components/Toast/Toast';
import useForm from '@/core/hooks/useForm';
import Actions from '@/core/lib/new-architecture/actions';
import type { ReportDonation } from '@/core/lib/new-architecture/actions/report.actions';
import { useToastContext } from '@/core/lib/toast/toast.context';
import { useTranslationContext } from '@/core/lib/translation/translation.context';
import type { ReportsReportFormDonation } from '@/core/types/donation';
import { reportsReportFormSchemaDonation } from '@/core/types/donation';
import { ReportDonationReasons } from '@/core/types/report';

interface DonationReportModalProps {
  donationId?: number;
  isOpen: boolean;
  onClose: () => void;
}

const DonationReportModal: React.FC<DonationReportModalProps> = ({ donationId, isOpen, onClose }) => {
  const { t } = useTranslationContext(['common']);
  const toast = useToastContext();
  const [showError, setShowError] = useState<boolean>(false);

  const { onReport, isLoading } = Actions.report.useReportDonation({
    onSuccess: () => {
      toast?.open(t('components.donation.report.reasons.submit.success', { ns: 'common' }), 2000, ToastStatus.SUCCESS);
      onClose();
    },
  });

  const { control, onSubmit, errors, onCleanGlobalError, watch, setValue, setFocus } = useForm<null, ReportsReportFormDonation>({
    schema: reportsReportFormSchemaDonation,
    mutationFn: data => {
      const body = {
        donation: donationId,
        reasons: [],
      } as ReportDonation;

      if (data.donation_error) {
        body.reasons.push(ReportDonationReasons.DONATION_ERROR);
      }
      if (data.donation_danger) {
        body.reasons.push(ReportDonationReasons.DONATION_DANGER);
      }
      if (data.donation_scam) {
        body.reasons.push(ReportDonationReasons.DONATION_SCAM);
      }
      if (data.donation_other) {
        body.reasons.push(ReportDonationReasons.DONATION_OTHER);
      }

      if (data.comment) {
        Object.assign(body, { comment: data.comment });
      }

      return onReport(body);
    },
  });

  const donationError = watch('donation_error');
  const donationDanger = watch('donation_danger');
  const donationScam = watch('donation_scam');
  const donationOther = watch('donation_other');
  const comment = watch('comment');

  useEffect(() => {
    if (errors.at_least_one_checked?.isError && !errors.global.isError) {
      setShowError(true);
    }
    if (donationError || donationDanger || donationScam || (donationOther && comment)) {
      setShowError(false);
    }
  }, [donationError, donationDanger, donationScam, donationOther, comment, errors]);

  const onTextAreaClick = () => {
    setValue('donation_other', true);
  };

  return (
    <Modal isOpen={isOpen} onClose={() => null}>
      <div className="flex h-full flex-col gap-5">
        <Header content={t('components.donation.report.reasons.nav-content', { ns: 'common' })} iconAfter="close" onAfter={onClose} />
        <div className="inline-flex h-full flex-col md:gap-9">
          {errors.global.isError && errors.global.message && (
            <Alert status="error" onClose={onCleanGlobalError}>
              {t(errors.global.message, { ns: 'common' })}
            </Alert>
          )}
          <FormContainer>
            <div className="flex flex-col space-y-3">
              <Controller
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      <CheckboxInput id="donation_error" onChange={onChange} value={value ?? false}>
                        <span className="text-body-secondary">{t('components.donation.report.reasons.donation-error', { ns: 'common' })}</span>
                      </CheckboxInput>
                      <hr className="text-stroke-tertiary" />
                    </>
                  );
                }}
                name="donation_error"
              />
              <Controller
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      <CheckboxInput id="donation_danger" onChange={onChange} value={value ?? false}>
                        <span className="text-body-secondary">{t('components.donation.report.reasons.donation-danger', { ns: 'common' })}</span>
                      </CheckboxInput>
                      <hr className="text-stroke-tertiary" />
                    </>
                  );
                }}
                name="donation_danger"
              />
              <Controller
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => {
                  return (
                    <>
                      <CheckboxInput id="donation_scam" onChange={onChange} value={value ?? false}>
                        <span className="text-body-secondary">{t('components.donation.report.reasons.donation-scam', { ns: 'common' })}</span>
                      </CheckboxInput>
                      <hr className="text-stroke-tertiary" />
                    </>
                  );
                }}
                name="donation_scam"
              />
              <Controller
                control={control}
                defaultValue={false}
                render={({ field: { onChange, value } }) => (
                  <>
                    <CheckboxInput
                      id="donation_other"
                      onChange={inputValue => {
                        if (inputValue === true) {
                          setFocus('comment');
                        }
                        onChange(inputValue);
                      }}
                      value={value ?? false}
                    >
                      <div className="flex flex-col">
                        <Controller
                          control={control}
                          render={({ field: { ref, onChange: OnTextAreaChange, value: textAreaValue } }) => (
                            <TextAreaInput
                              ref={ref}
                              id="comment"
                              value={textAreaValue ?? ''}
                              onChange={OnTextAreaChange}
                              onClick={onTextAreaClick}
                              placeholder={t('components.donation.report.reasons.donation-other', { ns: 'common' })}
                            />
                          )}
                          name="comment"
                        />
                      </div>
                    </CheckboxInput>
                    <HintMessage hasCharCounter inputLength={comment?.length ?? 0} max={400} />
                    {errors.comment?.isError && !errors.at_least_one_checked.isError && <HintMessage message={t(errors.comment.message as string, { ns: 'common' })} isError />}
                  </>
                )}
                name="donation_other"
              />
            </div>
            {showError && <HintMessage message={t(errors.at_least_one_checked.message as string, { ns: 'common' })} isError />}
          </FormContainer>
          <div className="mt-auto flex w-full justify-end">
            <div className="w-full md:max-w-max">
              <Button type="submit" variant="primary" size="large" onClick={onSubmit} isLoading={isLoading}>
                {t('components.donation.report.reasons.submit.text', { ns: 'common' })}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DonationReportModal;
